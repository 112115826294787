<template>
  <div>
    <header class="header">
      <v-layout class="header-wrap">
        <h1 class="header-title">
          <router-link :to="{ name: 'TopIndex' }" class="header-title__link">
            <img class="header-title__image" src="/static/icon/logo.svg" :alt="$t('site_name')" />
          </router-link>
        </h1>
        <v-spacer></v-spacer>
        <v-layout wrap justify-end class="header-nav hidden-mobile">
          <v-menu offset-y tile transition="scroll-y-transition">
            <template #activator="{ on, attrs }">
              <a class="header-nav__link" v-bind="attrs" href="#" v-on="on">
                <span class="header-nav__text">{{ $t('menu.service') }}</span>
                <span class="header-nav__add">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="mask0_376_7364"
                      style="mask-type: alpha"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_376_7364)">
                      <path
                        d="M11.9996 15.05L6.34961 9.375L7.39961 8.325L11.9996 12.925L16.5996 8.325L17.6496 9.375L11.9996 15.05Z"
                        fill="#1A1A1A"
                      />
                    </g>
                  </svg>
                </span>
              </a>
            </template>
            <v-list class="header-navBox">
              <v-list-item :to="{ name: 'SpotIndex' }" class="header-navBox__item">
                <v-list-item-title>{{ $t('model.service.service_name_6') }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'OnlineformIndex' }" class="header-navBox__item">
                <v-list-item-title>{{ $t('model.service.service_name_7') }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'SpotStatus' }" class="header-navBox__item">
                <v-list-item-title>{{ $t('model.service.service_name_4') }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'RouteStatus' }" class="header-navBox__item">
                <v-list-item-title>{{ $t('model.service.service_name_3') }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'AppIndex' }" class="header-navBox__item">
                <v-list-item-title>{{ $t('model.service.service_name_10') }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'ServiceDetail', params: { service_id: 1 } }" class="header-navBox__item">
                <v-list-item-title>{{ $t('model.service.service_name_1') }}</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'ServiceDetail', params: { service_id: 2 } }" class="header-navBox__item">
                <v-list-item-title>{{ $t('model.service.service_name_2') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <template v-if="$store.getters.getLoginMemberID !== null">
            <router-link
              :to="{ name: 'AccountIndex' }"
              class="header-nav__link"
              :class="{ 'is-current': $route.path.match(/^\/account(\/|\?|$)/) }"
            >
              <img src="/static/icon/icon_me.svg" />
              <span class="header-nav__text">{{ $t('menu.account_index') }}</span>
            </router-link>

            <router-link
              :to="{ name: 'MessageIndex' }"
              class="header-nav__link"
              :class="{ 'is-current': $route.path.match(/^\/message(\/|\?|$)/) }"
            >
              <img src="/static/icon/icon_inbox.svg" />
              <v-badge
                v-if="$store.getters.hasUnreads"
                color="#EC0000"
                :style="{ 'z-index': 2 }"
                :content="$store.getters.getUnreads > 99 ? '+99' : $store.getters.getUnreads"
              >
                <span class="header-nav__text">{{ $t('menu.message') }}</span>
              </v-badge>
              <template v-else>
                <span class="header-nav__text">{{ $t('menu.message') }}</span>
              </template>
            </router-link>

            <router-link
              :to="{ name: 'ReservationIndex' }"
              class="header-nav__link"
              :class="{ 'is-current': $route.path.match(/^\/reservation(\/|\?|$)/) }"
            >
              <img src="/static/icon/icon_calendar_edit.svg" />
              <span class="header-nav__text">{{ $t('menu.reservation_index_short') }}</span>
            </router-link>

            <!-- 申請履歴 -->
            <router-link
              :to="{ name: 'ApplicationIndex' }"
              class="header-nav__link"
              :class="{ 'is-current': $route.path.match(/^\/application(\/|\?|$)/) }"
            >
              <img src="/static/icon/icon_application.svg" />
              <span class="header-nav__text">{{ $t('menu.application_index_short') }}</span>
            </router-link>

            <router-link
              :to="{ name: 'ScheduleIndex' }"
              class="header-nav__link"
              :class="{ 'is-current': $route.path.match(/^\/schedule(\/|\?|$)/) }"
            >
              <img src="/static/icon/icon_calendar.svg" />
              <span class="header-nav__text">{{ $t('menu.schedule') }}</span>
            </router-link>
          </template>

          <router-link
            v-if="$store.getters.getLoginMemberID === null"
            :to="{ name: 'AboutIndex' }"
            class="header-nav__link"
            :class="{ 'is-current': $route.path.match(/^\/about(\/|\?|$)/) }"
          >
            <span class="header-nav__text">{{ $t('menu.about_index_short') }}</span>
          </router-link>

          <router-link
            v-if="$store.getters.getLoginMemberID === null"
            :to="{ name: 'NoticeIndex' }"
            class="header-nav__link"
            :class="{ 'is-current': $route.path.match(/^\/notice(\/|\?|$)/) }"
          >
            <img src="/static/icon/icon_notification.svg" />
            <span class="header-nav__text">{{ $t('menu.notice') }}</span>
          </router-link>

          <router-link
            :to="{ name: 'FaqIndex' }"
            class="header-nav__link"
            :class="{ 'is-current': $route.path.match(/^\/faq(\/|\?|$)/) }"
          >
            <img src="/static/icon/icon_help.svg" />
            <span class="header-nav__text">{{ $t('menu.faq') }}</span>
          </router-link>
        </v-layout>

        <div class="header-nav__button">
          <template v-if="$store.getters.getLoginMemberID == null">
            <router-link :to="{ name: 'LoginIndex' }" class="btn-login">
              <img class="btn-login__icon" src="/static/icon/icon_login_white.svg" />
              <span class="btn-login__text">{{ $t('menu.login_index') }}</span>
            </router-link>
          </template>
          <template v-else>
            <router-link :to="{ name: 'LogoutIndex' }" class="btn-logout">
              <img class="btn-logout__icon" src="/static/icon/icon_logout_orange.svg" />
              <span class="btn-logout__text">{{ $t('menu.logout_index') }}</span>
            </router-link>
          </template>
          <button class="btn-menu hidden-pc" @click="drawer = true">
            <img class="btn-menu__icon" src="/static/icon/icon_menu_kgreen600.svg" />
            <span class="btn-menu__text">{{ $t('menu_button') }}</span>
          </button>
        </div>
      </v-layout>
    </header>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      right
      fixed
      width="90vw"
      height="100vh"
      overlay-opacity="0.1"
      class="header-drawer hidden-pc"
      style="max-width: 480px; box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.1)"
    >
      <v-list nav dense class="header-drawer__inner">
        <v-list-item-group>
          <v-layout justify-end class="header-drawer__button">
            <button class="btn-menu" @click="drawer = false">
              <img class="btn-menu__icon" src="/static/icon/icon_close_kgreen600.svg" />
              <span class="btn-menu__text">{{ $t('close') }}</span>
            </button>
          </v-layout>
        </v-list-item-group>

        <hr class="header-drawer__divider--dashed" />

        <v-list-item-group>
          <v-layout justify-center class="header-drawer__button">
            <template v-if="$store.getters.getLoginMemberID === null">
              <router-link :to="{ name: 'LoginIndex' }" class="btn-accent btn-block">
                <img class="btn-accent__icon" src="/static/icon/icon_login_white.svg" />
                <span class="btn-accent__text">{{ $t('menu.login_index') }}</span>
              </router-link>
            </template>
            <template v-else>
              <router-link :to="{ name: 'LogoutIndex' }" class="btn-accent-secondary btn-block">
                <img class="btn-accent-secondary__icon" src="/static/icon/icon_logout_orange.svg" />
                <span class="btn-accent-secondary__text">{{ $t('menu.logout_index') }}</span>
              </router-link>
            </template>
          </v-layout>
        </v-list-item-group>

        <hr class="header-drawer__divider" />

        <v-list-group :value="true" no-action class="header-drawer__expantion mb-1">
          <template #activator>
            <v-list-item-content>
              <span class="header-drawer__title">{{ $t('menu.service') }}</span>
            </v-list-item-content>
          </template>

          <v-list-item
            class="header-drawer__item is-sub"
            :to="{ name: 'SpotIndex' }"
            :class="{
              'is-current': $route.path.match(/^\/spot(\/|\?|$)/) && !$route.path.match(/^\/spot\/status(\/|\?|$)/),
            }"
          >
            <div class="header-drawer__link">{{ $t('model.service.service_name_6') }}</div>
          </v-list-item>

          <v-list-item
            class="header-drawer__item is-sub"
            :to="{ name: 'OnlineformIndex' }"
            :class="{ 'is-current': $route.path.match(/^\/onlineform(\/|\?|$)/) }"
          >
            <div class="header-drawer__link">{{ $t('model.service.service_name_7') }}</div>
          </v-list-item>

          <v-list-item
            class="header-drawer__item is-sub"
            :to="{ name: 'SpotStatus' }"
            :class="{ 'is-current': $route.path.match(/^\/spot\/status(\/|\?|$)/) }"
          >
            <div class="header-drawer__link">{{ $t('model.service.service_name_4') }}</div>
          </v-list-item>

          <v-list-item
            class="header-drawer__item is-sub"
            :to="{ name: 'RouteStatus' }"
            :class="{ 'is-current': $route.path.match(/^\/route\/status(\/|\?|$)/) }"
          >
            <div class="header-drawer__link">{{ $t('model.service.service_name_3') }}</div>
          </v-list-item>

          <v-list-item
            class="header-drawer__item is-sub"
            :to="{ name: 'AppIndex' }"
            :class="{ 'is-current': $route.path.match(/^\/app(\/|\?|$)/) }"
          >
            <div class="header-drawer__link">{{ $t('model.service.service_name_10') }}</div>
          </v-list-item>

          <v-list-item class="header-drawer__item is-sub" :to="{ name: 'ServiceDetail', params: { service_id: 1 } }">
            <div class="header-drawer__link">{{ $t('model.service.service_name_1') }}</div>
          </v-list-item>

          <v-list-item class="header-drawer__item is-sub" :to="{ name: 'ServiceDetail', params: { service_id: 2 } }">
            <div class="header-drawer__link">{{ $t('model.service.service_name_2') }}</div>
          </v-list-item>
        </v-list-group>

        <v-list-item-group>
          <v-list-item
            class="header-drawer__item"
            :to="{ name: 'AboutIndex' }"
            :class="{ 'is-current': $route.path.match(/^\/about(\/|\?|$)/) }"
          >
            <div class="header-drawer__link">{{ $t('menu.about_index') }}</div>
          </v-list-item>
        </v-list-item-group>

        <template v-if="$store.getters.getLoginMemberID !== null">
          <v-list-item-group>
            <v-list-item-title class="header-drawer__groupTitle"
              ><span>{{ $t('menu_account') }}</span></v-list-item-title
            >
            <v-list-item
              class="header-drawer__item"
              :to="{ name: 'AccountIndex' }"
              :class="{ 'is-current': $route.path.match(/^\/account(\/|\?|$)/) }"
            >
              <div class="header-drawer__link">
                <img class="header-drawer__icon" src="/static/icon/icon_me.svg" />
                {{ $t('menu.account_index') }}
              </div>
            </v-list-item>

            <v-list-item
              class="header-drawer__item"
              :to="{ name: 'MessageIndex' }"
              :class="{ 'is-current': $route.path.match(/^\/message(\/|\?|$)/) }"
            >
              <div class="header-drawer__link">
                <img class="header-drawer__icon" src="/static/icon/icon_inbox.svg" />
                <v-badge
                  v-if="$store.getters.hasUnreads"
                  color="#EC0000"
                  :content="$store.getters.getUnreads > 99 ? '+99' : $store.getters.getUnreads"
                >
                  {{ $t('menu.message') }}
                </v-badge>
                <template v-else>
                  {{ $t('menu.message') }}
                </template>
              </div>
            </v-list-item>

            <v-list-item
              class="header-drawer__item"
              :to="{ name: 'ReservationIndex' }"
              :class="{ 'is-current': $route.path.match(/^\/reservation(\/|\?|$)/) }"
            >
              <div class="header-drawer__link">
                <img class="header-drawer__icon" src="/static/icon/icon_calendar_edit.svg" />
                {{ $t('menu.reservation_index_short') }}
              </div>
            </v-list-item>

            <!-- 申請履歴 -->
            <v-list-item
              class="header-drawer__item"
              :to="{ name: 'ApplicationIndex' }"
              :class="{ 'is-current': $route.path.match(/^\/application(\/|\?|$)/) }"
            >
              <div class="header-drawer__link">
                <img class="header-drawer__icon" src="/static/icon/icon_application.svg" />
                {{ $t('menu.application_index_short') }}
              </div>
            </v-list-item>

            <v-list-item
              class="header-drawer__item"
              :to="{ name: 'ScheduleIndex' }"
              :class="{ 'is-current': $route.path.match(/^\/schedule(\/|\?|$)/) }"
            >
              <div class="header-drawer__link">
                <img class="header-drawer__icon" src="/static/icon/icon_calendar.svg" />
                {{ $t('menu.schedule') }}
              </div>
            </v-list-item>
          </v-list-item-group>
        </template>

        <v-list-item-group>
          <v-list-item-title class="header-drawer__groupTitle"
            ><span>{{ $t('menu_support') }}</span></v-list-item-title
          >
          <v-list-item
            class="header-drawer__item"
            :to="{ name: 'NoticeIndex' }"
            :class="{ 'is-current': $route.path.match(/^\/notice(\/|\?|$)/) }"
          >
            <div class="header-drawer__link">
              <img class="header-drawer__icon" src="/static/icon/icon_notification.svg" />
              {{ $t('menu.notice') }}
            </div>
          </v-list-item>

          <v-list-item
            class="header-drawer__item"
            :to="{ name: 'FaqIndex' }"
            :class="{ 'is-current': $route.path.match(/^\/faq(\/|\?|$)/) }"
          >
            <div class="header-drawer__link">
              <img class="header-drawer__icon" src="/static/icon/icon_help.svg" />
              {{ $t('menu.faq') }}
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <div v-if="!$route.path.match(/^\/schedule(\/|\?|$)/)" class="nav-breadcrumbsWrap">
      <v-breadcrumbs :items="breadcrumbs" divider=">" class="nav-breadcrumbs" />
      <v-spacer />
      <LocaleSwitch />
    </div>
  </div>
</template>
<script>
import LocaleSwitch from '@/components/locale/LocaleSwitch'

export default {
  name: 'AppToolbar',
  components: {
    LocaleSwitch,
  },

  data: () => ({
    drawer: false,
  }),

  computed: {
    breadcrumbs() {
      const { matched } = this.$route
      let prev = null
      return matched
        .map((route, index) => {
          const to =
            index === matched.length - 1 ? this.$route.path : 'path' in route ? route.path || '/' : route.redirect
          const text = this.$t('menu.' + route.meta.title)
          const ignored = to === prev || to === prev + '/'
          prev = to
          if (ignored) {
            return null
          }
          return {
            text: text,
            to: to,
            exact: true,
            disabled: false,
          }
        })
        .filter((route) => route !== null)
    },
  },
}
</script>
